import Navbar from '../components/Navbar';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import ParticlesBackground from '../components/ParticlesBackground';
import ServicesSection from '../components/ServicesSection';
import AboutSection from '../components/AboutSection';
import ContactSection from '../components/ContactSection';

export default function HomePage() {
    return (
        <div className="min-h-screen bg-gray-50">
          <Navbar />
          
          {/* Hero Section */}
          <section className="gradient-bg pt-32 pb-20 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
            <ParticlesBackground />
            <div className="max-w-7xl mx-auto relative z-10">
              <div className="text-center">
                <motion.h1 
                  className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  IT Support for Growing Businesses
                </motion.h1>
                
                <motion.div
                  className="mb-6"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <span className="text-4xl md:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500">
                    $0 INITIAL COST
                  </span>
                  <span className="text-xl text-gray-200 ml-2">
                    + $45 per month
                  </span>
                </motion.div>
                
                <motion.p 
                  className="text-xl text-gray-200 mb-8 max-w-2xl mx-auto"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  Empowering small and medium businesses worldwide with enterprise-grade IT solutions
                </motion.p>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <a 
                    href="#contact" 
                    className="inline-flex items-center bg-accent text-white px-6 py-3 rounded-lg font-medium hover:bg-accent/90 transition-colors"
                  >
                    Get Started
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </a>
                </motion.div>
              </div>
            </div>
          </section>
    
          {/* Lazy loaded sections con IntersectionObserver */}
          <div className="content-visibility-auto">
            <ServicesSection />
            <AboutSection />
            <ContactSection />
          </div>
    
          <footer className="bg-primary text-white py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
              <div className="grid md:grid-cols-3 gap-8 mb-8">
                <div>
                  <h3 className="text-xl font-bold mb-4">Webber</h3>
                  <p className="text-gray-300">Soluciones IT empresariales</p>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-4">Contacto</h3>
                  <p className="text-gray-300">Email: info@webber.com.ar</p>
                  <p className="text-gray-300">Tel: +54 9 11 3691-7205</p>
                </div>
                <div>
                  <h3 className="text-xl font-bold mb-4">Suscripción</h3>
                  <p className="text-gray-300">Servicio bajo suscripción mensual.</p>
                  <p className="text-gray-300">No incluye período de prueba gratuito.</p>
                </div>
              </div>
              
              <div className="border-t border-gray-700 pt-8">
                <div className="text-sm text-gray-300">
                  <p className="mb-4">© 2024 Webber. Todos los derechos reservados.</p>
                  <p className="mb-2">
                    Al utilizar nuestros servicios, usted acepta nuestros{' '}
                    <Link to="/terms" className="text-accent hover:underline">
                        Términos y Condiciones
                    </Link>
                    {', '}
                    <Link to="/privacy" className="text-accent hover:underline">
                        Política de Privacidad
                    </Link>
                    {' '}y{' '}
                    <Link to="/refund" className="text-accent hover:underline">
                        Política de Reembolsos
                    </Link>
                    .
                  </p>
                  <p className="text-xs">
                    El uso de nuestros servicios está sujeto al pago de una suscripción mensual. 
                    Los precios están sujetos a cambios previo aviso de 30 días. 
                    La cancelación debe realizarse con 15 días de anticipación al siguiente ciclo de facturación. 
                    No ofrecemos períodos de prueba gratuitos. 
                    El servicio se factura por adelantado al inicio de cada período mensual.
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
}