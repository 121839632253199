import { CheckCircle2 } from 'lucide-react';

export default function AboutSection() {
  return (
    <section id="about" className="bg-gray-100 py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl font-bold text-primary mb-6">Why Choose Webber?</h2>
            <div className="space-y-4">
              {[
                "24/7 Technical Support",
                "Global Coverage",
                "Customized Solutions",
                "Competitive Pricing"
              ].map((item, i) => (
                <div key={i} className="flex items-center">
                  <CheckCircle2 className="text-accent mr-3" size={24} />
                  <span className="text-gray-700">{item}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="relative">
            <img 
              src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80&w=800&h=600"
              alt="IT Support Team"
              width={800}
              height={600}
              className="rounded-lg shadow-xl w-full h-auto"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}