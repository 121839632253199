import { useCallback, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

interface Particle {
  x: number;
  y: number;
  size: number;
  speedX: number;
  speedY: number;
  opacity: number;
}

export default function ParticlesBackground() {
  const [particles, setParticles] = useState<Particle[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const createParticle = useCallback(() => {
    if (!containerRef.current) return {
      x: 0,
      y: 0,
      size: 0,
      speedX: 0,
      speedY: 0,
      opacity: 0
    };

    const rect = containerRef.current.getBoundingClientRect();
    
    return {
      x: Math.random() * rect.width,
      y: Math.random() * rect.height,
      size: Math.random() * 2 + 1,
      speedX: (Math.random() - 0.5) * 0.3,
      speedY: (Math.random() - 0.5) * 0.3,
      opacity: Math.random() * 0.3 + 0.1
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    const initialParticles = Array.from({ length: 100 }, createParticle);
    setParticles(initialParticles);

    const interval = setInterval(() => {
      if (!containerRef.current) return;
      const rect = containerRef.current.getBoundingClientRect();

      setParticles(prevParticles => 
        prevParticles.map(particle => ({
          ...particle,
          x: (particle.x + particle.speedX + rect.width) % rect.width,
          y: (particle.y + particle.speedY + rect.height) % rect.height
        }))
      );
    }, 50);

    const handleResize = () => {
      setParticles(Array.from({ length: 100 }, createParticle));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, [createParticle]);

  return (
    <div 
      ref={containerRef}
      className="absolute inset-0 pointer-events-none"
      style={{ overflow: 'hidden' }}
    >
      {particles.map((particle, index) => (
        <motion.div
          key={index}
          className="absolute rounded-full bg-white"
          animate={{
            x: particle.x,
            y: particle.y,
          }}
          transition={{
            duration: 0.05,
            ease: "linear"
          }}
          style={{
            width: particle.size,
            height: particle.size,
            opacity: particle.opacity,
            filter: 'blur(0.5px)'
          }}
        />
      ))}
    </div>
  );
}