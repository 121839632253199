import { useState } from 'react';
import { motion } from 'framer-motion';
import { Globe2, Shield, Server } from 'lucide-react';

// Definir los tipos
interface ServiceCardProps {
  icon: React.ElementType;
  title: string;
  desc: string;
}

const ServiceCard = ({ icon: Icon, title, desc }: ServiceCardProps) => {
  const [rotate, setRotate] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const card = e.currentTarget;
    const box = card.getBoundingClientRect();
    const x = e.clientX - box.left;
    const y = e.clientY - box.top;
    
    const centerX = box.width / 2;
    const centerY = box.height / 2;
    
    const rotateX = (y - centerY) / 20;
    const rotateY = (centerX - x) / 20;

    setRotate({ x: rotateX, y: rotateY });
  };

  return (
    <motion.div
      className="bg-white/10 backdrop-blur-md p-8 rounded-xl shadow-lg transition-shadow duration-300"
      style={{
        transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg)`,
        transition: 'transform 0.1s'
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setRotate({ x: 0, y: 0 })}
      whileHover={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)" }}
    >
      <Icon className="w-12 h-12 text-accent mb-4" />
      <h3 className="text-xl font-bold mb-3">{title}</h3>
      <p className="text-gray-800 text-lg">{desc}</p>
    </motion.div>
  );
};

export default function ServicesSection() {
  return (
    <section id="services" className="py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-primary text-center mb-12">Our Services</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {[
            { icon: Server, title: "Infrastructure Management", desc: "24/7 monitoring and maintenance of your IT infrastructure" },
            { icon: Shield, title: "Cybersecurity", desc: "Advanced security solutions to protect your business data" },
            { icon: Globe2, title: "Cloud Solutions", desc: "Seamless cloud integration and migration services" }
          ].map((service, i) => (
            <ServiceCard key={i} icon={service.icon} title={service.title} desc={service.desc} />
          ))}
        </div>
      </div>
    </section>
  );
}